//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-24:_136,_944,_480,_860,_132,_928,_496,_992;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-24')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-24', "_136,_944,_480,_860,_132,_928,_496,_992");
        }
      }catch (ex) {
        console.error(ex);
      }