
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function interchangesRT () {
    function scopeBrandNamePartNumbers1(interchanges, interchange, interchangeIndex) {
        var brandName = interchange[0];
        var partNumbers = interchange[1];
        return _createElement('div', {
            'className': 'fitment-row',
            'key': brandName
        }, _createElement('div', { 'className': 'fitment-cell' }, _createElement('div', { 'className': 'fitment-cell__value' }, brandName)), _createElement('div', { 'className': 'fitment-cell' }, _createElement('div', { 'className': 'fitment-cell__value' }, partNumbers)));
    }
    function repeatInterchange2(interchanges, interchange, interchangeIndex, brandName, partNumbers) {
        return scopeBrandNamePartNumbers1.apply(this, [
            interchanges,
            interchange,
            interchangeIndex
        ]);
    }
    function scopeInterchanges3() {
        var interchanges = Object.entries(JSON.parse(this.productData[0].value));
        return _createElement('div', {
            'className': 'cm_fitment-table fitment-table-container',
            'key': '0'
        }, _createElement('div', { 'className': 'cm_fitment-table__horizontal' }, _createElement.apply(this, [
            'div',
            { 'className': 'fitment-table-body' },
            _createElement('div', { 'className': 'fitment-row' }, _createElement('div', { 'className': 'fitment-cell' }, _createElement('div', { 'className': 'fitment-cell__value' }, 'Brand Name')), _createElement('div', { 'className': 'fitment-cell' }, _createElement('div', { 'className': 'fitment-cell__value' }, 'Part #'))),
            _map(interchanges, repeatInterchange2.bind(this, interchanges))
        ])));
    }
    return this.productData.length ? scopeInterchanges3.apply(this, []) : null;
}
        export const componentNames = []