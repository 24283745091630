
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'zoom-fade-animation-element-wrapper grid__item product-item-block view-mode-grid ' + (window.innerWidth < 1024 ? 'medium-up--one-third' : 'medium-up--one-quarter') + ' small--one-half' }, _createElement('div', { 'className': 'product-card product-card-wrapper' }, _createElement('div', { 'className': 'product-item__label-list' }, this.on_sale ? _createElement('span', {
                    'className': 'product-label product-label--on-sale',
                    'key': '312'
                }, '\n        ', Math.round((1 - this.price / this.compare_at_price) * 100), '% Off\n      ') : null, this.out_of_stock ? _createElement('span', {
                    'className': 'product-label product-label--soldout',
                    'key': '483'
                }, 'Sold Out') : null), _createElement('div', { 'className': 'product-item--media' }, _createElement('div', { 'className': 'product-card__image-with-placeholder-wrapper' }, _createElement('a', {
                    'href': this.url,
                    'className': 'list-view-item__link-image product-card__link-image'
                }, _createElement('div', { 'className': 'list-view-item__image-wrapper product-card__image-wrapper' }, _createElement('div', {}, _createElement('img', {
                    'className': 'list-view-item__image product-card__image zoom-fade-animation-element',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })))))), _createElement('div', { 'className': 'product-item--info' }, _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'product-item__vendor link'
                }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('a', {
                    'href': this.url,
                    'className': 'item__link-title product-card__link-title',
                    'aria-label': this.removeHTML(this.title)
                }, _createElement('span', mergeProps({ 'className': 'h4 item__title product-card__title' }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-item__price_and_reviews_row' }, _createElement('div', { 'className': 'price price--listing' + (this.on_sale ? ' price--on-sale' : '') }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '1763'
                }, this.price_varies ? _createElement('span', { 'key': '1826' }, 'From: ') : null, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '1994'
                }, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price)), _createElement('div', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)))) : null)), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '23460'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'data-product-id': this.id,
                    'encType': 'multipart/form-data',
                    'onClick': e => window.Convermax.addItemToCart(e),
                    'key': '2749'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': '1'
                }), _createElement('button', {
                    'id': 'product-add-to-cart',
                    'className': 'btn btn--primary product-form__cart-submit',
                    'type': 'submit',
                    'name': 'add',
                    'data-btn-addtocart': this.id,
                    'data-available': '1'
                }, _createElement('span', { 'data-add-to-cart-text': true }, 'Add to cart'), _createElement('svg', {
                    'className': 'icon icon--cart',
                    'viewBox': '0 0 27 24',
                    'role': 'presentation'
                }, _createElement('g', {
                    'transform': 'translate(0 1)',
                    'strokeWidth': '2',
                    'stroke': 'currentColor',
                    'fill': 'none',
                    'fillRule': 'evenodd'
                }, _createElement('circle', {
                    'strokeLinecap': 'square',
                    'cx': '11',
                    'cy': '20',
                    'r': '2'
                }), _createElement('circle', {
                    'strokeLinecap': 'square',
                    'cx': '22',
                    'cy': '20',
                    'r': '2'
                }), _createElement('path', { 'd': 'M7.31 5h18.27l-1.44 10H9.78L6.22 0H0' }))))) : null, this.out_of_stock ? _createElement('div', {
                    'className': 'klaviyo-button-container',
                    'key': '3806'
                }, _createElement('button', {
                    'className': 'btn btn--primary',
                    'onClick': () => window.Convermax.openCustomKlaviyoDialog(this)
                }, _createElement('span', {}, 'Notify me when available'))) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products product-recommendations__inner half_row_mobile page-width zoom-fade-animation half_row_mobile zoomFade-animation' }, _createElement('div', { 'className': 'section-header text-center cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You may also like')), _createElement.apply(this, [
        'div',
        { 'className': 'zoom-fade-animation grid grid--uniform grid--view-items use_align_height zoomFade-animation cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []