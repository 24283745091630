import shopifyPursuitGenericDefaults from '../shopify-pursuit-generic/config.js';

window.Convermax.openCustomKlaviyoDialog = (product) => {
  window.Convermax.klaviyoModalActiveProduct = product;

  window.Convermax.openDialog('CustomKlaviyoDialog');
};

window.Convermax.addBackInStockSubscribe = () => {
  const submitBtn = window.document.querySelector('#klaviyo-bis-modal .cm_klaviyo-dialog_btn');
  const completeMessage = window.document.querySelector('#klaviyo-bis-modal #completed_message');
  const errorMessage = window.document.querySelector('#klaviyo-bis-modal #error_message');

  const userEmail = window.document.querySelector('#klaviyo-bis-modal #email').value;

  const variantSelect = window.document.querySelector('#variants');
  const variantId = variantSelect.options[variantSelect?.selectedIndex].getAttribute('data-variant-id');

  if (!userEmail) {
    errorMessage.innerHTML = 'Please provide a valid email address.';
    errorMessage.style.display = 'block';
    return;
  }

  submitBtn.setAttribute('disabled', 'true');

  const data = {
    data: {
      type: 'back-in-stock-subscription',
      attributes: {
        profile: {
          data: {
            type: 'profile',
            attributes: {
              email: userEmail,
            },
          },
        },
        channels: ['EMAIL'],
      },
      relationships: {
        variant: {
          data: {
            type: 'catalog-variant',
            id: `$shopify:::$default:::${variantId}`,
          },
        },
      },
    },
  };

  fetch('https://a.klaviyo.com/client/back-in-stock-subscriptions?company_id=VuWCUe', {
    method: 'POST',
    headers: {
      'revision': '2025-01-15',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`${res.status} status error. Please try again later.`);
      }

      submitBtn.style.display = 'none';
      completeMessage.style.display = 'block';
    })
    .catch((err) => {
      submitBtn.removeAttribute('disabled');
      errorMessage.innerHTML = err.message;
      errorMessage.style.display = 'block';
      console.error(err);
    });
};

window.Convermax.addItemToCart = (e) => {
  e.preventDefault();

  fetch('/cart/add.js', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: window.theme.Helpers.serialize(e.target.closest('form')),
  })
    .then((response) => {
      return response.json();
    })
    .then(() => {
      const button = e.target.closest('.product-form__cart-submit');
      const addToCartBtnText = button.querySelector('[data-add-to-cart-text]');

      button.classList.add('added');
      addToCartBtnText.innerHTML = 'Added!';
      window.theme.Cart.prototype._onProductAdded();

      setTimeout(function () {
        button.classList.remove('added');
        addToCartBtnText.innerHTML = 'Add To Cart';
      }, 2000);
    });
};

const handleYmmCollapseOnBurgerClick = () => {
  const burgerToggle = window.document.querySelector('.js-mobile-nav-toggle');

  const observer = new MutationObserver((mutationsList) => {
    const ymmHeader = window.document.querySelector('#cm-ymm-mobile > .cm_vehicle-widget__header');
    const ymmDropdowns = window.document.querySelector('#cm-ymm-mobile .cm_collapsible-elem');

    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const isOpen = burgerToggle.classList.contains('mobile-nav--open');

        if (isOpen) {
          ymmHeader.classList.remove('cm_column');
          ymmHeader.classList.add('cm_collapsed');
          ymmHeader.style = '';

          ymmDropdowns.classList.add('collapsed');
        } else {
          ymmHeader.classList.remove('cm_collapsed');
          ymmHeader.classList.add('cm_column');
          ymmHeader.style.pointerEvents = 'none';

          ymmDropdowns.classList.remove('collapsed');
          ymmDropdowns.style = '';

          // adjust hamburger menu height after YMM expand
          const burgerMenu = window.document.querySelector('.mobile-nav-wrapper');
          if (burgerMenu?.classList.contains('js-menu--is-open')) {
            setTimeout(() => {
              burgerMenu.style.top = `${window.document.querySelector('.site-header').clientHeight}px`;
            }, 19);
          }
        }
      }
    }
  });

  observer.observe(burgerToggle, { attributes: true });
};

const AfterInit = () => {
  const isSearchPage = window.location.pathname === '/pages/search';
  const isCollectionPage = window.location.pathname.startsWith('/collections/');
  const contentHeader = window.document.querySelector('.section-header');

  if ((isSearchPage || isCollectionPage) && contentHeader) {
    const addVehicleLabel = (vehicle, isSearchPage) => {
      const vehicleString = `${Object.values(vehicle).join(' ')}`;

      if (isSearchPage) {
        const header = window.document.querySelector('.page_header_heading');
        if (header) {
          header.innerHTML = `<div class="cm-vehicle-label">Vehicle Search Results<span class="cm-vehicle-label_title">${vehicleString ? `: <span class="cm-vehicle-label_vehicle">${vehicleString}</span>` : ''}</span></div>`;
        }
      } else {
        let vehicleLabel = window.document.querySelector('.cm-vehicle-label');

        if (!vehicleLabel) {
          vehicleLabel = window.document.createElement('div');
          vehicleLabel.classList.add('cm-vehicle-label');
          vehicleLabel.innerHTML = `<a href="/pages/search">< Shop all parts<span class="cm-vehicle-label_title">${vehicleString ? `&nbspfor <span class="cm-vehicle-label_vehicle">${vehicleString}</span>` : ''}</span></a>`;

          contentHeader.prepend(vehicleLabel);
        } else {
          window.document.querySelector('.cm-vehicle-label_title').innerHTML =
            `&nbspfor <span class="cm-vehicle-label_vehicle">${vehicleString}</span>`;
        }
      }
    };

    window.Convermax.onVehicleSelected = (vehicle) => addVehicleLabel(vehicle, isSearchPage);
    addVehicleLabel(window.Convermax.getVehicle(), isSearchPage);
  }

  handleYmmCollapseOnBurgerClick();
};

const onVehicleDiscarded = () => {
  const labelTitle = window.document.querySelector('.cm-vehicle-label_title');
  if (labelTitle) {
    labelTitle.innerHTML = '';
  }
};

export default {
  includes: ['shopify-pursuit-generic'],
  ...shopifyPursuitGenericDefaults,
  AfterInit,
  collapsibleAnimationTime: 0,
  fitmentSearch: {
    ...shopifyPursuitGenericDefaults.fitmentSearch,
    onVehicleDiscarded,
  },
  Widgets: [
    ...shopifyPursuitGenericDefaults.Widgets.filter(
      (w) => !['CategoryPage', 'HeaderVehicleWidget', 'SearchVehicleWidget', 'FitmentTable'].includes(w.name),
    ),
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm',
        class: 'cm_vehicle-widget__header_desktop cm_mobile-hide',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 0,
    },
    {
      name: 'HomeVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm-mobile',
        class: 'cm_vehicle-widget__home_mobile',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 800,
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'HomeVehicleWidget_tablet',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm-tablet',
        class: 'cm_vehicle-widget__home_tablet',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 800,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { replace: '.cm_fitment-table-tab', class: 'cm_tab-title cm_fitment-table-tab active' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'InterchangesTab',
      type: 'ProductData',
      location: { replace: '.cm_interchange-tab', class: 'cm_tab-title cm_interchange-tab' },
      template: 'product/interchangesTab',
      fields: ['interchanges'],
    },
    {
      name: 'Interchanges',
      type: 'ProductData',
      location: '.cm_interchange-tab-content',
      template: 'product/interchanges',
      fields: ['interchanges'],
    },
    {
      name: 'CustomKlaviyoDialog',
      type: 'ContextDialog',
      template: 'main/klaviyoDialog',
    },
  ],
};
