
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function klaviyoDialogRT () {
    function mergeProps(inline, external) {
        var res = Object.assign({}, inline, external);
        if (inline.hasOwnProperty('style')) {
            res.style = _.defaults(res.style, inline.style);
        }
        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
            res.className = external.className + ' ' + inline.className;
        }
        return res;
    }
    function scopeVariantArrIdName2(variant, variantIndex) {
        var variantArr = variant.split(';');
        var id = variantArr[0];
        var name = variantArr[1];
        return [_createElement('option', {
                'value': id,
                'data-variant-id': id,
                'key': '11411'
            }, name)];
    }
    function repeatVariant3(variant, variantIndex, variantArr, id, name) {
        return scopeVariantArrIdName2.apply(this, [
            variant,
            variantIndex
        ]);
    }
    return _createElement('div', { 'className': 'cm_klaviyo-dialog_container dialog-container' }, _createElement('div', { 'className': 'cm_dialog-title-container' }, _createElement('div', {
        'className': 'close klaviyo-bis-close',
        'data-cm-role': 'close'
    }, '\xD7')), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'id': 'klaviyo-bis-modal' }, _createElement('div', { 'id': 'container' }, _createElement('h3', mergeProps({ 'className': 'modal-title' }, { dangerouslySetInnerHTML: { __html: window.Convermax.klaviyoModalActiveProduct.title } })), _createElement('p', {}, 'Register to receive a notification when this item comes back in stock.'), _createElement('form', {
        'action': '#',
        'className': 'form-horizontal clearfix'
    }, _createElement('div', { 'className': 'form-group' }, _createElement('div', {
        'id': 'variant_select',
        'className': 'col-sm-12 ' + (window.Convermax.klaviyoModalActiveProduct.variant_ids.length === 1 && 'cm_hide')
    }, _createElement.apply(this, [
        'select',
        {
            'id': 'variants',
            'className': 'selectpicker form-control input-lg'
        },
        !window.Convermax.klaviyoModalActiveProduct.variant_ids_fully ? _createElement('option', {
            'value': window.Convermax.klaviyoModalActiveProduct.variant_ids[0],
            'data-variant-id': window.Convermax.klaviyoModalActiveProduct.variant_ids[0],
            'key': '842'
        }, '\n                  Default Title\n                ') : null,
        _map(window.Convermax.klaviyoModalActiveProduct.variant_ids_fully, repeatVariant3.bind(this))
    ]))), _createElement('div', { 'className': 'form-group' }, _createElement('input', {
        'required': true,
        'type': 'email',
        'id': 'email',
        'placeholder': 'Email',
        'className': 'form-control input-lg'
    }), _createElement('div', {
        'id': 'newsletter_subscription',
        'style': { display: 'none' }
    }, _createElement('p', {}, _createElement('input', {
        'type': 'checkbox',
        'id': 'subscribe_for_newsletter',
        'checked': true
    }), _createElement('label', { 'htmlFor': 'subscribe_for_newsletter' }, 'Add me to your email list.')))), _createElement('div', {
        'onClick': () => window.Convermax.addBackInStockSubscribe(),
        'className': 'cm_klaviyo-dialog_btn btn btn-success btn-lg col-xs-12'
    }, _createElement('span', {}, 'Notify me when available')), _createElement('div', {
        'id': 'error_message',
        'className': 'error_message alert alert-danger',
        'style': { display: 'none' }
    }), _createElement('div', {
        'id': 'completed_message',
        'className': 'completed_message alert alert-success',
        'style': { display: 'none' }
    }, '\n            You\'re in! We\'ll let you know when it\'s back.\n            ', _createElement('a', {
        'href': '#',
        'data-cm-role': 'close',
        'className': 'klaviyo-bis-close'
    }, 'Close'))), _createElement('p', { 'className': 'small-print' })))));
}
        export const componentNames = []