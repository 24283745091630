//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-24:_4928,_8865,_4464,_5380,_9984,_2248,_6448,_5456;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-24')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-24', "_4928,_8865,_4464,_5380,_9984,_2248,_6448,_5456");
        }
      }catch (ex) {
        console.error(ex);
      }